<template>
  <div id="create-group">
    <div class="row justify-content-start">
        <div class="col-12">
            <div class="form-group mb-3">
                <label for="reviewer_name">
                   Reviewer's Name
                    <span class="text-danger">*</span>
                </label>
                <input type="text" v-model="data.reviewer_name" id="reviewer_name" class="form-control" placeholder="Reviewer's name" />
            </div>
        </div>
        <div class="col-12 mb-3">
            <vue-dropzone id="reviewPhotoDrop" ref="reviewPhotoDrop" class="border-0" 
            :use-custom-slot="true" :maxFiles="1">
                <div class="my-0 needsclick">
                    <i class="h1 text-primary mdi mdi-plus"></i>
                    <h6 class="mb-1">Upload Photo</h6>
                    <span class="text-primary font-13">
                        <em>Reviewer's Image (optional)</em>
                    </span>
                </div>
            </vue-dropzone>
        </div>
        <div class="col-12">
            <div class="form-group mb-3">
                <label class="d-block">Review Date</label>
                <date-picker v-model="data.reviewed_at" placehoder="Choose Date" :editable="false" :first-day-of-week="1" format="YYYY-MM-DD" lang="en"></date-picker>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group mb-3">
                <label>Select Country</label>
                <multiselect
                    :options="countries.map(c => c.id)"
                    :custom-label="parseCountry"
                    v-model="data.country_id"
                    placeholder="--Select Country--"
                    :close-on-select="true"
                    :clear-on-select="false"
                ></multiselect>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group mb-3">
                <label>Choose Product</label>
                <select class="custom-select" v-validate="{required: true}" v-model="data.product_id" name="product" placeholder="-- select product--">
                <option disabled value="" key="-1">-- Choose Product --</option>
                <option :value="product.id" v-for="(product, index) in productList" :key="index">{{product.name}}</option>
                </select>
                <span class="text-danger invalid-feedback"  v-show="errors.has('product')">{{ errors.first('product') }}</span>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group w-100">
                <textarea  v-model="data.content" class="form-control" rows="4" placeholder="Write Review" v-validate="'max:5000'" name="content"></textarea>
                <span class="text-danger font-sm"  v-show="errors.has('content')">{{ errors.first('content') }}</span>
            </div>
        </div>
        <div class="col-12">
            <div>
                <b-input-group>
                    <b-input-group-prepend>
                        <b-button variant="light" @click="data.rating = null">Clear</b-button>
                    </b-input-group-prepend>
                    <b-form-rating v-model="data.rating"  stars="5" color="#ff8800"></b-form-rating>
                    <b-input-group-append>
                        <b-input-group-text class="justify-content-center" style="min-width: 3em;">
                        {{ data.rating }}
                        </b-input-group-text>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
    </div>
    <div class="form-group text-left mt-3 mb-0">
        <button class="btn btn-primary" @click.prevent="isUpdateMode ? updateData() : createData()"
         type="button">Create</button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import VueDropzone from '@/components/VueDropzone.vue';

export default{
  components: {
    Multiselect,
    DatePicker,
    VueDropzone
  },
  data() {
    return {
      data: {
        reviewer_name: "",
        content: "",
        reviewed_at: null,
        country_id: null,
        rating: null,
        product_id:null
      },
      isUpdateMode: false,
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    productList() {
      return this.$store.state.productList.products.map((pro) => {
        return { id:pro.id, name:pro.name} });
    },
  },
  methods: {
    parseCountry(id){
      let con = this.countries.find(item => item.id == id);
      if(con){ return con.name; }
    },
    setUpdateMode(data){
        let newObj = { id: data.id }
        for (const property in this.data) {
            property === 'reviewed_at'
            ? newObj[property] = new Date(data[property])
            : newObj[property] = data[property]
        }
        this.data = {...newObj}
        this.isUpdateMode = true
    },
    createData() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.collectFiles().length){ formData.append('reviewer_image', this.collectFiles()[0]) }
        this.requestProcessor(formData, '/create')
      })
    },
    updateData(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.collectFiles().length){ formData.append('reviewer_image', this.collectFiles()[0]);}
        this.requestProcessor(formData, `/${this.data.id}/update`, 'UPDATE')
      })
    },
    requestProcessor(formData, url, action = "ADD"){
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/reviews${url}`,formData)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.$store.commit(`reviewList/${action}_REVIEW`, response.data.data)
          this.resetForm();
        }
      })
    },
    collectFiles(){
        var arrafile = [];
        if(this.$refs.reviewPhotoDrop){
            arrafile = [...this.$refs.reviewPhotoDrop.getAcceptedFiles()];
        }
        return arrafile
    },
    resetForm() {
      this.data = {
        reviewer_name: "",
        content: "",
        reviewed_at: null,
        country_id: null,
        rating:null,
        product_id:null
      }
      this.isUpdateMode = false;
      if(this.$refs.reviewPhotoDrop){
        this.$refs.reviewPhotoDrop.removeAllFiles();
      }
      this.$emit('resetForm');
      this.$validator.reset()
    },
  },
  created() {
    this.$store.dispatch("productList/fetchProducts")
  },
}
</script>
